import "./Founder-Section.css";
import kaveh from "../kaveh.jpg";

const Founder = () => {
  return (
    <div className="background1">
      <div className="category">
        <div className="summary">
          <img className="kavehpic" src={kaveh} alt="founder" />
          <p className="name">ARIAN SALARI</p>
          <p className="desc">FOUNDER</p>
        </div>

        <div className="details">
          <p className="meet">MEET THE FOUNDER</p>
          <p className="writing">
            Spark Media was founded by Arian Salari who has spent over a year
            optimizing for businesses online and carrying out social media
            work.
          </p>
          <p className="writing">
            As a result of being involved in several profiles, Arian
            has picked up many different talents and is very flexible to work
            with. Our team prioritizes the client and will stop at nothing to
            make sure that their demands are met with service that is up to par.
          </p>
          <p className="name2">- Founder</p>
        </div>
      </div>
    </div>
  );
};

export default Founder;
