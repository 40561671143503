import coding2 from "../ig-2.png";
import "./Third-Section.css";

const Third = () => {
  return (
    <div className="parting">
      <div className="heading">
        <h1 className="approach">OUR APPROACH</h1>
        <h1 className="advertising">
          “Social Media Platforms That Everyone Would Love”
        </h1>
      </div>
      <div className="information">
        <div className="paragraphs">
          <p className="paragraph">What we do is very simple:</p>
          <p className="paragraph">
            We first take any inspiration and ideas from you then design sleek
            and attractive social interfaces for gaining maximized recognition
            amongst targeted audiences.
          </p>
          <p className="paragraph">
            After you are satisfied with the roadmap planned for your business’
            growth, we will create and manage your social interfaces keeping
            them up to date and functioning.
          </p>
          <p className="paragraph">
            While doing all of this, you will also have access to everything you
            may need as well, meaning you will oversee the success and growth as
            we work on it.
          </p>

         
        </div>
        <img className="instagram" src={coding2} alt="Responsive website" />
      </div>
    </div>
  );
};

export default Third;
