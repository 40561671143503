import "./Middle-Section.css";

const Middle = () => {
  return (
    <div className="background">
      <div className="top-info">
        <p className="revgen">FOLLOWERS GAINED</p>
        <p className="topnum">13,000+</p>
      </div>
      <div className="card-div">
        <div className="card1">
          <p className="topnum">2</p>
          <p className="bottom">CLIENTS HELPED</p>
        </div>
        <div className="card1">
          <p className="topnum">2</p>
          <p className="bottom">SIZE OF TEAM</p>
        </div>
        <div className="card1">
          <p className="topnum">1</p>
          <p className="bottom">SERVICE OFFERED</p>
        </div>
      </div>
    </div>
  );
};

export default Middle;
